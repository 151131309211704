// Override default variables before the import
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

$lightgreen: #528c2f;
$darkgrey: #333333;
$white: #ffffff;

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;600&display=swap");

body {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 137%;
  color: $darkgrey;
  // border-top: 3px solid red;
  &.header-fix {
    padding-top: 155px;

    @include media-breakpoint-down(sm) {
      padding-top: 96px;
    }
  }
  @include media-breakpoint-down(md) {
    font-size: 20px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.mobile {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.desktop {
  display: block;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

h1 {
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: $white;

  @include media-breakpoint-down(sm) {
    font-size: 28px;
    line-height: 36px;
  }
}

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 122%;
  font-size: 20px;
  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 27px;
    margin-bottom: 8px;
  }
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

h4 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
}

h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
}

a {
  color: $darkgrey;
  &:hover {
    color: $lightgreen;
  }
}

.green {
  h4,
  h5 {
    color: $lightgreen;
  }
}

/******************************
/
/     Header Module
/
******************************/

.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  &.gradient {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.56) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  transition: all 0.5s;
  .wrapper {
    svg {
      height: 40px;
    }
    max-width: 1440px;
    margin: 0 auto 0 auto;
    padding: 30px 65px 20px 65px;
    @include media-breakpoint-down(md) {
      padding: 20px 16px 6px 16px;
      svg {
        height: 36px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 20px 10px 16px 20px;
      svg {
        height: 28px;
      }
    }
  }
  li {
    display: inline-block;
    margin-left: 50px;
    padding-top: 0px;
    a {
      color: $white;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
    }
    @include media-breakpoint-down(md) {
      margin-left: 30px;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .menu {
    position: absolute;
    right: 30px;
    z-index: 5;
    top: -30px;
    cursor: pointer;
  }
  &.sticky {
    .menu {
      svg {
        stroke: $darkgrey;
      }
    }
    background: $white;
    li {
      a {
        color: $darkgrey;
      }
    }
    transition: all 0.5s;
  }
  &.dark {
    .menu {
      svg {
        stroke: $darkgrey;
      }
    }
    li {
      a {
        color: $darkgrey;
      }
    }
  }
}

/******************************
/
/     Mobile Nav Module
/
******************************/

.navigation-panel {
  opacity: 0;
  left: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 100vh;
  background: $lightgreen;
  padding: 16px 0px 6px 20px;
  transition: all 0.5s;
  ul {
    padding: 0px;
    margin-top: 56px;
  }
  li {
    a {
      color: $white;
      font-size: 26px;
      line-height: 36px;
      text-transform: capitalize;
    }
    padding-bottom: 10px;
  }
  .small-logo {
    svg {
      width: 89px;
    }
  }
  .close {
    font-weight: 200;
    letter-spacing: 2px;
    text-shadow: none;
    font-size: 12px;
    color: $white;
    display: inline-block;
    margin-right: 20px;
    margin-top: 6px;
    opacity: 1;
    cursor: pointer;
  }
  &.open {
    opacity: 1;
    left: 0;
    transition: all 0.5s;
  }
}

/******************************
/
/     Banner Module
/
******************************/

.banner {
  width: 100%;
  height: 904px;
  margin-bottom: 88px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    height: 904px;
    position: relative;
    @include media-breakpoint-down(md) {
      padding: 0 32px;
    }
    @include media-breakpoint-down(sm) {
      height: 760px;
      padding: 0 20px;
    }
    .align-bottom {
      z-index: 9;
      position: absolute;
      bottom: 65px;
      width: 75%;
      @include media-breakpoint-down(md) {
        width: 95%;
        bottom: 32px;
      }
      @include media-breakpoint-down(sm) {
        bottom: 20px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 72px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 48px;
    height: 760px;
  }
}

/******************************
/
/     Bio Banner Module
/
******************************/

.bio-banner {
  width: 100%;
  height: 606px;
  margin-bottom: 97px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-color: #f5f5f5;
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 65px;
    height: 606px;
    position: relative;
    @include media-breakpoint-down(md) {
      padding: 0 32px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
      height: 610px;
    }
    h2 {
      font-size: 24px;
      line-height: 28px;
      &.light {
        font-weight: 200;
      }
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
    .align-bottom {
      z-index: 9;
      position: absolute;
      bottom: 65px;
      width: 90%;
      @include media-breakpoint-down(sm) {
        bottom: auto;
        top: 100px;
      }
    }
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 72px;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 28px;
    height: 610px;
    background-position: center bottom;
    background-size: 320%;
  }
}

/******************************
/
/     Column Builder Module
/
******************************/

.column-builder {
  width: 100%;
  margin-bottom: 104px;
  h2 {
    font-size: 20px;
    line-height: 27px;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 27px;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .wrapper {
    max-width: 1440px;
    margin: 0 auto 0 auto;
    padding: 0 65px 0 65px;
    @include media-breakpoint-down(md) {
      padding: 0 32px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
    a {
      color: $lightgreen;
      font-size: 16px;
      line-height: 22px;
    }
    .bold-600 {
      p {
        font-weight: 400;
      }
    }
    p {
      margin-bottom: 16px;
      line-height: 30px;
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &.who-we-are {
    @include media-breakpoint-down(sm) {
      margin-bottom: 12px;
    }
  }
  &.two-images {
    margin-bottom: 120px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 2px;
      img {
        margin-bottom: 32px;
      }
      margin-bottom: 12px;
    }
  }
  &.growth-development {
    margin-bottom: 56px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 42px;
    }
  }
  &.our-people {
    margin-bottom: 98px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 48px;
    }
  }
  &.title-only {
    margin-bottom: 32px;
  }
  &.images-only {
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
      img {
        margin-bottom: 32px;
      }
      margin-bottom: 0px;
    }
  }
  &.small-text {
    img {
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }
    a {
      color: $lightgreen;
      @include media-breakpoint-down(sm) {
        display: inline-block;
      }
    }
  }
  &.contact-us {
    margin-bottom: 120px;
    h2 {
      font-size: 20px;
      line-height: 27px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    p {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      @include media-breakpoint-down(sm) {
        margin-bottom: -20px;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
      }
    }
  }
  &.contact-details {
    margin-bottom: 118px;
    h2 {
      border-top: 1px solid $darkgrey;
      padding-top: 16px;
      color: $lightgreen;
      font-weight: 600;
      margin-bottom: 68px;
      font-size: 20px;
      line-height: 27px;
      @include media-breakpoint-down(sm) {
        margin-top: 48px;
        margin-bottom: 36px;
      }
    }
    h4 {
      color: $lightgreen;
      font-size: 16px;
      line-height: 25px;
      font-weight: 600;
      margin-bottom: 36px;
      margin-top: 48px;
      @include media-breakpoint-down(sm) {
        border-top: 1px solid #e5e5e5;
        margin-top: 36px;
        padding-top: 16px;
      }
    }
    h5 {
      margin-top: 36px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      @include media-breakpoint-down(sm) {
        margin-top: 24px;
      }
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 8px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
      }
    }
    a {
      color: $darkgrey;
    }
    a[href*="https://goo"]
    {
      color: $lightgreen;
      @include media-breakpoint-down(sm) {
      }
    }
  }
  &.about {
    a {
      color: $darkgrey !important;
    }
  }
}

/******************************
/
/     Footer Module
/
******************************/

.footer {
  background: $darkgrey;
  width: 100%;
  height: 100px;
  .wrapper {
    padding: 0 65px;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 35px;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    @include media-breakpoint-down(md) {
      padding-left: 32px;
      padding-right: 32px;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
